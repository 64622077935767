import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/logo-full-black.png";
import sign from "../../assets/formis-sign.png";
import { useEffect, useState } from "react";
import { invoiceApi } from "../../api";
import { Toaster, toast } from "sonner";

const OrderInvoice = () => {
  const { invoiceId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({});

  const fetchInvoiceDetails = async () => {
    setLoading(true);
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(invoiceApi + invoiceId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setInvoiceDetail(data.data);
        setLoading(false);
        if (data.status === 10) {
          localStorage.removeItem("accessToken");
          toast.error(data.message);
          navigate("/");
        }
      } else {
        // console.log(data.message);
      }
    } catch {
      console.log("something went wrong!!!!");
    }
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  return (
    <div className="relative">
      {loading && (
        <div className="absolute top-2 right-6">
          <div className="loader"></div>
        </div>
      )}
      <section className="text-formisBlack14 flex items-center justify-between px-2 mb-5 mt-5 lg:mt-0 mx-6">
        <h1 className="text-2xl md:text-2xl mb-1 font-extralight">
          Invoice Details
        </h1>
      </section>

      <div className="text-formisBlack14 bg-white mx-6 rounded-[10px] p-5">
        <section>
          <div className="flex items-center justify-between">
            <img src={logo} alt="FORMIS" className="w-[180px] md:w-[250px]" />
            <h2 className="text-formisOrange font-bold text-2xl md:text-3xl">
              INVOICE
            </h2>
          </div>
        </section>

        <section className="mt-5">
          <h3 className="font-semibold">MANAGED & FULFILLED BY</h3>
          <hr className="border-t-2 border-formisBlack15 my-3" />
          <div className="text-sm grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="flex gap-3 xl:gap-10">
              <div className="font-light border-l-2 border-formisBlack15 pl-3">
                <p>{invoiceDetail?.setting?.company_name}</p>
                <p>{invoiceDetail?.setting?.address1}</p>
                <p>{invoiceDetail?.setting?.address2}</p>
                <p>Gurgaon, Haryana 122002</p>
              </div>
              <div className="font-light border-l-2 border-formisBlack15 pl-3 h-fit">
                <p>GST NUMBER (GSTIN)</p>
                <p className="font-bold">{invoiceDetail?.setting?.gst_no}</p>
              </div>
            </div>

            <div className="flex gap-3 xl:gap-10">
              <div className="border-l-2 border-formisBlack15 pl-3 h-fit">
                <p>INVOICE NUMBER</p>
                <p className="font-bold">{invoiceId}</p>
              </div>
              <div className="border-l-2 border-formisBlack15 pl-3 h-fit">
                <p>BILLED BY</p>
                <p className="font-bold">
                  {invoiceDetail?.invoice?.pharmacy?.pharmacy_name}
                </p>
              </div>
              <div className="border-l-2 border-formisBlack15 pl-3 h-fit">
                <p>DATE AND TIME</p>
                <p className="font-bold">
                  {invoiceDetail?.invoice?.order_date}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5 flex flex-col xl:flex-row gap-5">
          <div className="xl:max-w-[30%] text-end">
            <div>
              <h3 className="font-semibold pr-8">BILL FROM</h3>
              <hr className="border-t-2 border-formisBlack15 my-2" />
              <div className="text-sm font-light pr-8">
                <p className="font-bold">
                  {invoiceDetail?.invoice?.pharmacy?.pharmacy_name}
                </p>
                <p>{invoiceDetail?.invoice?.pharmacy?.address1}</p>
                <p>{invoiceDetail?.invoice?.pharmacy?.address2}</p>
                <p>Gurgaon, Haryana 122002</p>
                <p>
                  DL Licence:{" "}
                  <span className="font-bold">
                    {invoiceDetail?.invoice?.pharmacy?.dl_license}
                  </span>
                </p>
                <p>
                  BILL NUMBER:{" "}
                  <span className="font-bold">
                    {invoiceDetail?.invoice?.bill_no}
                  </span>
                </p>
                <p>
                  GST NUMBER (GSTIN):{" "}
                  <span className="font-bold">
                    {invoiceDetail?.invoice?.pharmacy?.gst_no}
                  </span>
                </p>
              </div>
            </div>
            <div className="mt-5">
              <h3 className="font-semibold pr-8">BILL TO</h3>
              <hr className="border-t-2 border-formisBlack15 my-2" />
              <div className="text-sm font-light pr-8">
                <p className="font-bold text-formisOrange">
                  {invoiceDetail?.invoice?.user?.name}
                </p>
                <p className="mt-2">
                  {invoiceDetail?.invoice?.order?.delivery_location}
                </p>
                <p className="mt-2">
                  Phone:{" "}
                  <span className="font-bold">
                    {invoiceDetail?.invoice?.user?.mobile_no}
                  </span>
                </p>
                <div className="font-bold mt-2">
                  <p>URGENCY</p>
                  <p className="text-formisOrange">ASAP</p>
                </div>
                <div className="font-bold mt-2">
                  <p>PAYMENT</p>
                  <p className="text-formisOrange">UNPAID</p>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:w-[70%] overflow-x-scroll">
            <table className="w-full text-sm">
              <thead>
                <tr>
                  <th className="p-2 w-[5%]">SR.</th>
                  <th className="p-2 w-[20%]">MEDICINE NAME</th>
                  <th className="p-2 w-[20%]">MFG</th>
                  <th className="p-2 w-[10%]">PACK SIZE</th>
                  <th className="p-2 w-[10%]">AMOUNT</th>
                  <th className="p-2 w-[10%]">QTY.</th>
                  <th className="p-2 w-[10%]">GST</th>
                  <th className="p-2 w-[10%]">MRP</th>
                </tr>
              </thead>
              <tbody>
                {invoiceDetail?.invoice?.invoice_prescription?.map(
                  (prescription, index) => (
                    <tr key={index}>
                      <td className="table-li2 border-formisBlack15">
                        {index + 1}
                      </td>
                      <td className="table-li2 border-formisBlack15">
                        {prescription.name}
                      </td>
                      <td className="table-li2 border-formisBlack15">
                        {prescription.prescription.manufacturer_name}
                      </td>
                      <td className="table-li2 border-formisBlack15">
                        {prescription.prescription.pack_size}
                      </td>
                      <td className="table-li2 border-formisBlack15">
                        ₹ {prescription.selling_price}
                      </td>
                      <td className="table-li2 border-formisBlack15">
                        {prescription.quantity}
                      </td>
                      <td className="table-li2 border-formisBlack15">
                        ₹ {prescription.selling_price_tax}
                      </td>
                      <td className="table-li2 border-formisBlack15">
                        ₹ {prescription.total_selling_amount}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <div className="text-sm grid grid-cols-2 xl:grid-cols-5 auto-rows-auto gap-5 mt-5">
              <div className="border-l-2 pl-2 border-formisBlack15 h-fit">
                <p className="font-bold">TERMS & CONDITIONS</p>
                <p>
                  Products once sold are final except for wrong, expired, or
                  damaged items. Disputes under Haryana jurisdiction. Not a
                  prescription.
                </p>
              </div>

              <div className="border-l-2 pl-2 border-formisBlack15 h-fit">
                <div className="flex justify-between">
                  <p>CGST</p>₹{" "}
                  {(
                    invoiceDetail?.gst_groups?.reduce(
                      (acc, group) => acc + (group.cgst_value ?? 0),
                      0
                    ) ?? 0
                  ).toFixed(2)}
                </div>
                <div className="flex justify-between">
                  <p>SGST</p>₹{" "}
                  {(
                    invoiceDetail?.gst_groups?.reduce(
                      (acc, group) => acc + (group.sgst_value ?? 0),
                      0
                    ) ?? 0
                  ).toFixed(2)}
                </div>
                <div className="flex justify-between">
                  <p>Total GST</p>₹{" "}
                  {(
                    invoiceDetail?.gst_groups?.reduce(
                      (acc, group) => acc + (group.total_tax_amount ?? 0),
                      0
                    ) ?? 0
                  ).toFixed(2)}
                </div>
              </div>

              <div className="border-l-2 pl-2 border-formisBlack15 h-fit">
                <div className="flex justify-between">
                  <p>Total Item(s)</p>
                  {invoiceDetail?.invoice?.invoice_prescription?.length}
                </div>
                <div className="flex justify-between">
                  <p>Total</p> ₹{" "}
                  {(
                    invoiceDetail?.invoice?.order?.total_item_selling_price ?? 0
                  ).toFixed(2)}
                </div>
                <div className="flex justify-between">
                  <p>Discount</p> ₹{" "}
                  {(
                    invoiceDetail?.invoice?.order?.total_discount_amount ?? 0
                  ).toFixed(2)}
                </div>
              </div>

              <div className="col-span-2 border-l-2 pl-2 border-formisBlack15 h-fit">
                <div className="flex justify-between">
                  <p>
                    Delivery Fees{" "}
                    <s>
                      (₹{" "}
                      {(
                        invoiceDetail?.invoice?.invoice_prescription?.length ??
                        0
                      ).toFixed(2)}
                      )
                    </s>
                  </p>
                  <p>₹ 0</p>
                </div>
                <div className="flex justify-between">
                  <p>
                    Convenience Fees{" "}
                    <s>
                      (₹{" "}
                      {(
                        invoiceDetail?.invoice?.order
                          ?.total_item_selling_price ?? 0
                      ).toFixed(2)}
                      )
                    </s>
                  </p>
                  <p>₹ 0</p>
                </div>
                <div className="flex justify-between">
                  <p>
                    Platform Fees{" "}
                    <s>
                      (₹{" "}
                      {(
                        invoiceDetail?.invoice?.order?.total_discount_amount ??
                        0
                      ).toFixed(2)}
                      )
                    </s>
                  </p>
                  <p>₹ 0</p>
                </div>
              </div>
            </div>
            <div className="bg-formisBlack15 text-formisWhitef0 font-bold flex w-fit gap-5 px-5 py-3 place-self-end mt-5 xl:mt-0">
              <p>NET</p>
              <p>₹ {invoiceDetail?.invoice?.total_selling_amount}</p>
            </div>
          </div>
        </section>

        <section className="flex flex-col items-end mt-5 xl:mt-0">
          <img src={sign} alt="Formis.." className="max-w-36" />
          <p className="font-bold pr-6">SIGNATURE</p>
        </section>
      </div>
      <Toaster
        position="top-right"
        expand={false}
        closeButton
        richColors
        visibleToasts={2}
      />
    </div>
  );
};

export default OrderInvoice;
