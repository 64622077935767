import { useEffect, useState } from "react";
import { orderDetailsApi } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";

const OrderDetails = () => {
  const { orderCode } = useParams();
  const [loading, setLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [orderStatus, setOrderStatus] = useState();

  const [timeUntilApiCall, setTimeUntilApiCall] = useState(30);
  const [countdown, setCountdown] = useState(null);

  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    setLoading(true);
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(orderDetailsApi + orderCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setOrderDetail(data.data);
        setOrderStatus(data.data.order.status.name);
        setLoading(false);
        if (data.status === 10) {
          localStorage.removeItem("accessToken");
          toast.error(data.message);
          navigate("/");
        }
      }
    } catch (err) {
      toast.error("Session expired, please login to continue");
      navigate("/");
    }
  };

  // useEffect(() => {
  //   fetchOrderDetails();
  //   const intervalId = setInterval(() => {
  //     fetchOrderDetails();
  //   }, 30000);
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  useEffect(() => {
    if (timeUntilApiCall > 0) {
      const countdownTimer = setTimeout(() => {
        setTimeUntilApiCall((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearTimeout(countdownTimer);
    } else {
      fetchOrderDetails();
      setTimeUntilApiCall(30);
    }
  }, [timeUntilApiCall]);

  useEffect(() => {
    if (timeUntilApiCall <= 10 && timeUntilApiCall > 0) {
      setCountdown(timeUntilApiCall);
    } else {
      setCountdown(null);
    }
  }, [timeUntilApiCall]);

  return (
    <div className="text-formisBlack14">
      {loading && (
        <div className="fixed top-0 left-0 z-50 bg-formisBlack15/30 w-screen h-screen flex items-center justify-center">
          <div className="loader"></div>
        </div>
      )}

      <section className="sticky top-0 bg-formisWhitef0 z-40 text-formisBlack14 flex items-center justify-between p-6">
        {countdown !== null ? (
          <h1 className="text-xl md:text-2xl mb-1 font-extralight">
            Refreshing in{" "}
            <span className="text-formisOrange rounded-full">
              {countdown} {countdown === 1 ? "second" : "seconds"}
            </span>
          </h1>
        ) : (
          <h1 className="text-xl md:text-2xl mb-1 font-extralight">
            {orderCode} -{" "}
            <span className="text-formisOrange rounded-full">
              {orderStatus === "Draft" ? "Upcoming Order" : orderStatus}
            </span>
          </h1>
        )}
      </section>

      <div className="flex flex-col lg:flex-row">
        <div className="mx-6 lg:w-[30%]">
          {/* Pharmacy Details */}
          {orderDetail?.order?.status.name !== "Draft" && (
            <section className="bg-white rounded-[10px] px-5 py-10 mb-6 flex flex-col gap-4">
              <div className="flex gap-2">
                <p className="font-semibold">Pharmacy Name: </p>
                <p>{orderDetail?.order?.pharmacy?.pharmacy_name ?? "NA"}</p>
              </div>
              <div className="flex gap-2">
                <p className="font-semibold">Pharmacy Mobile: </p>
                <p>{orderDetail?.order?.pharmacy?.contact_mobile_no ?? "NA"}</p>
              </div>
            </section>
          )}

          {/* Rider Details */}
          {orderDetail?.order?.status.name !== "Draft" && (
            <section className="bg-white rounded-[10px] px-5 py-10 mb-6 flex flex-col gap-4">
              <div className="flex gap-2">
                <p className="font-semibold">Rider Name: </p>
                <p>{orderDetail?.order?.delivery_user?.name ?? "NA"}</p>
              </div>
              <div className="flex gap-2">
                <p className="font-semibold">Rider Mobile: </p>
                <p>{orderDetail?.order?.delivery_user?.mobile_no ?? "NA"}</p>
              </div>
            </section>
          )}

          {/* Invoice Table - Mobile */}
          <section className="lg:hidden bg-white overflow-x-scroll lg:mx-0 lg:mr-6 px-5 py-10 mb-6 rounded-[10px] w-full">
            <p className="mb-5 font-bold">Packing List</p>

            <table className="w-full">
              <thead>
                <tr>
                  <th className="w-[20%] table-li">Prescription Name</th>
                  <th className="w-[10%] table-li">Quantity</th>
                </tr>
              </thead>

              <tbody>
                {orderDetail?.order?.order_prescription?.map(
                  (prescription, index) => (
                    <tr key={index} className="border-t">
                      <td className="table-li">
                        {prescription.prescription.name}
                      </td>
                      <td className="table-li">{prescription.quantity}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </section>

          {/* Customer Details */}
          <section className="bg-white rounded-[10px] px-5 py-10 mb-6 flex flex-col gap-4">
            <p className="text-lg font-bold">Customer Details</p>
            <div className="flex gap-2">
              <p className="font-semibold">Customer Name: </p>
              <p>{orderDetail?.order?.user?.name} </p>
            </div>
            <div>
              <p className="font-semibold">Delivery Address: </p>
              <p>{orderDetail?.order?.delivery_location}</p>
            </div>
            <a
              href={
                orderDetail?.order &&
                `https://www.google.com/maps?q=${orderDetail?.order?.latitude},${orderDetail?.order?.longitude}`
              }
              className="text-formisOrange"
              target="_blank"
            >
              View Address on Map
            </a>
          </section>

          {/* Order Details */}
          <section className="bg-white rounded-[10px] px-5 py-10 mb-6 flex flex-col gap-4">
            <div className="flex gap-2">
              <p className="font-semibold">Order Status: </p>
              <p>{orderStatus === "Draft" ? "Upcoming Order" : orderStatus}</p>
            </div>

            {orderDetail?.order?.status?.name === "Delivered" &&
              (() => {
                const startLog = orderDetail.order.order_log.find(
                  (log) => log.description === "Start to Pickup"
                );
                const endLog = orderDetail.order.order_log.find(
                  (log) => log.description === "Delivered"
                );

                if (startLog && endLog) {
                  const startTime = new Date(startLog.updated_at);
                  const endTime = new Date(endLog.updated_at);

                  const timeDiffInSeconds = Math.floor(
                    (endTime - startTime) / 1000
                  );

                  const minutes = Math.floor(timeDiffInSeconds / 60);
                  const seconds = timeDiffInSeconds % 60;

                  return (
                    <div>
                      <p className="font-semibold">Time Taken to Deliver:</p>
                      <p className="text-formisOrange">
                        {minutes} minutes and {seconds} seconds
                      </p>
                    </div>
                  );
                }
                return null;
              })()}
          </section>

          {/* Order Tracking */}
          <section className="bg-white rounded-[10px] px-5 py-10 mb-6">
            <p className="mb-4 font-bold text-lg">Track Order</p>
            <div className="mb-4 flex gap-2">
              <p className="font-semibold">Order ID: </p>
              <p>{orderCode}</p>
            </div>
            {orderDetail?.order &&
              orderDetail?.order?.order_log?.map((log, index) => (
                <ul className="" key={index}>
                  <OrderTrack
                    orderId={orderCode}
                    title={
                      log.description ===
                      `#${orderCode} Order created successfully`
                        ? "Customer Placed Order"
                        : log.description
                    }
                    updatedat={log.updated_at}
                  />
                </ul>
              ))}
          </section>
        </div>

        <div className="lg:w-[70%]">
          {/* Invoice Table - Desktop */}
          <section className="hidden lg:block bg-white overflow-x-scroll mx-6 lg:mx-0 lg:mr-6 px-5 py-10 mb-6 rounded-[10px]">
            <p className="mb-5">Packing List</p>

            <table className="w-full">
              <thead>
                <tr>
                  <th className="w-[20%] table-li">Prescription Name</th>
                  <th className="w-[10%] table-li">Quantity</th>
                </tr>
              </thead>

              <tbody>
                {orderDetail?.order?.order_prescription?.map(
                  (prescription, index) => (
                    <tr key={index} className="border-t">
                      <td className="table-li">
                        {prescription.prescription.name}
                      </td>
                      <td className="table-li">{prescription.quantity}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
      <Toaster
        position="top-right"
        expand={false}
        closeButton
        richColors
        visibleToasts={2}
      />
    </div>
  );
};

export default OrderDetails;

const OrderTrack = ({ title, updatedat }) => {
  const utcTime = updatedat;

  const date = new Date(utcTime);

  const istOffset = 5.5 * 60 * 60 * 1000;
  const istTime = new Date(date.getTime() + istOffset);

  let istHours = istTime.getUTCHours();
  const istMinutes = istTime.getUTCMinutes().toString().padStart(2, "0");
  const istSeconds = istTime.getUTCSeconds().toString().padStart(2, "0");
  const istDay = istTime.getUTCDate().toString().padStart(2, "0");
  const istMonth = (istTime.getUTCMonth() + 1).toString().padStart(2, "0");
  const istYear = istTime.getUTCFullYear();

  const amOrPm = istHours >= 12 ? "PM" : "AM";
  istHours = istHours % 12 || 12;

  if (title.toLowerCase() === "invoice was generated")
    title = "Pharmacy Generated Bill";
  else if (title.toLowerCase() === "start to pickup")
    title = "Rider is Going to Pickup the Order";
  else if (title.toLowerCase() === "reached pickup location")
    title = "Rider Reached at Pharmacy";
  else if (title.toLowerCase() === "picked order item")
    title = "Rider is Out For Delivery";
  else if (title.toLowerCase() === "reached customer location")
    title = "Rider Reached Delivery Address";
  else if (title.toLowerCase() === "delivered") title = "Order Was Delivered";

  const formattedISTTime = `${istDay}/${istMonth}/${istYear} ${istHours}:${istMinutes}:${istSeconds} ${amOrPm}`;
  return (
    <li className="relative border-l-2 border-formisBlack15/20 pl-4 pb-4">
      <div className="absolute size-[0.6rem] rounded-full bg-formisOrange -left-[0.35rem] top-5"></div>
      <p>{title}</p>
      <span>{formattedISTTime}</span>
    </li>
  );
};

// useEffect(() => {
//   const id = setInterval(() => setCount((oldCount) => oldCount>0 && oldCount - 1), 1000);
//   console.log(count);
//   return () => clearInterval(id);
// }, [count]);

// if (
//   orderDetail?.order?.pharmacy_approval === 1 &&
//   orderDetail?.order?.customer_approval === 0 &&
//   orderDetail?.order?.is_customer_cancelled !== 1 &&
//   (orderDetail?.order?.status?.name).toLowerCase() !==
//     "cancelled-out of stock"
// ) {
//   setOrderStatus("Awaiting Customer Approval");
// } else {
//   setOrderStatus(orderDetail?.order?.status?.name)
// }
