import { IoMdCalendar } from "react-icons/io";
import { Link } from "react-router-dom";

const OrderBrief = ({
  username,
  orderid,
  status,
  createdat,
  lat,
  long,
  pharmacyid,
}) => {
  const pharmacyIdName = {
    19: "Anshu Mediways",
    20: "Alive Mediways",
    21: "Guru Nanak Medicos",
    22: "Goyal Chemist",
    28: "Kushal Pharmacy",
    29: "Green Medicals",
    30: "Live Pharmacy",
    31: "Live Pharmacy JMD Galleria",
  };

  const pharmacyName = pharmacyIdName[pharmacyid];

  return (
    <Link to={`/order/${orderid}`}>
      <div className="bg-formisWhitef0 p-5 border rounded-[10px] mb-5">
        <section>
          <div className="flex flex-col justify-between">
            <div className="flex gap-2 items-center justify-between">
              <h3 className="text-lg capitalize">{username}</h3>
            </div>
            <p>{orderid}</p>
            <p className="text-lg">{pharmacyName}</p>
          </div>

          {/* {lat !== null && long !== null && (
            <a
              href={
                lat && long && `https://www.google.com/maps?q=${lat},${long}`
              }
              target="_blank"
              className="bg-formisBlack14 text-formisWhitef5 px-3 py-2 text-center rounded-[10px] cursor-pointer"
            >
              View Order Location
            </a>
          )} */}
          <p className="text-formisOrange pb-2 pt-1">
            {status === "Draft" ? "Upcoming Order" : status}
          </p>
          <p className="flex justify-center bg-formisBlack14 text-formisWhitef5 px-3 py-2 text-center rounded-[10px] cursor-pointer">
            View Order Details
          </p>
        </section>
      </div>
    </Link>
  );
};

export default OrderBrief;
