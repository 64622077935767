import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Orders from "./pages/Order/Orders";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import ProtectedRoute from "./components/ProtectedRoute";
import OrderDetails from "./pages/Order/OrderDetails";
import OrderInvoice from "./pages/Order/OrderInvoice";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="flex justify-between">
        {/* <Sidebar /> */}
        <div className="w-full bg-formisWhitef0 text-formisWhitef5">
          <Topbar />
          <Routes>
            <Route path="/" element={<Login />} />
            {/* <Route path="/" element={<ProtectedRoute />}> */}
            <Route path="/dashboard/order/" element={<Orders />} />
            <Route path="/order/:orderCode" element={<OrderDetails />} />
            <Route path="/invoices/:invoiceId" element={<OrderInvoice />} />
            {/* </Route> */}
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
