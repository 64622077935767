import OrderBrief from "../../components/orders/OrderBrief";
import { useEffect, useState } from "react";
import { orderApiMain } from "../../api";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";

const Orders = () => {
  const [orderPageData, setOrderPageData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchOrderMain = async () => {
    setLoading(true);
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(orderApiMain, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setOrderPageData(data.data);
        setLoading(false);
        if (data.status === 10) {
          localStorage.removeItem("accessToken");
          toast.error(data.message);
          navigate("/");
        }
      }
    } catch (error) {
      toast.error("Session expired, please login to continue");
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderMain();
    const intervalId = setInterval(() => {
      fetchOrderMain();
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bg-formisWhitef0">
      {/* {loading && (
        <div className="absolute top-2 right-6">
          <div className="loader"></div>
        </div>
      )} */}
      {loading && (
        <div className="fixed top-0 left-0 z-50 bg-formisBlack15/30 w-screen h-screen flex items-center justify-center">
          <div className="loader"></div>
        </div>
      )}
      <section className="text-formisBlack14 flex items-center justify-between px-2 my-2 mx-6 mt-5">
        <h1 className="text-2xl md:text-2xl mb-1 font-extralight">
          Orders Inquiry
        </h1>
      </section>

      <div className="mx-3 md:mx-6 flex flex-col lg:flex-row gap-4 mt-5">
        <section className="bg-white text-formisBlack14 p-5 md:px-5 rounded-[10px] h-fit lg:w-[25%]">
          <h2 className="text-xl mb-2">Upcoming Orders</h2>
          <p className="mb-3">Your Upcoming Orders are listed here.</p>

          {orderPageData?.all_new_orders ? (
            orderPageData?.all_new_orders?.map((order, index) => (
              <OrderBrief
                key={index}
                username={order.user.name}
                orderid={order.code}
                lat={order.latitude}
                long={order.longitude}
                status={order.status.name}
              />
            ))
          ) : (
            <h3 className="text-xl">No Records Found</h3>
          )}
        </section>

        <section className="bg-white text-formisBlack14 p-5 md:px-5 rounded-[10px] h-fit lg:w-[25%]">
          <h2 className="text-xl mb-2">Manual Orders</h2>
          <p className="mb-3">Your Manual Orders are listed here.</p>
          {orderPageData?.all_manual_orders ? (
            orderPageData?.all_manual_orders?.map((order, index) => (
              <OrderBrief
                key={index}
                username={order.consumer_name}
                orderid={order.code}
                createdat={order.created_at}
                lat={order.latitude}
                long={order.longitude}
                status={order.status.name}
              />
            ))
          ) : (
            <h3 className="text-xl">No Records Found</h3>
          )}
        </section>

        <section className="bg-white text-formisBlack14 p-5 md:px-5 rounded-[10px] h-fit lg:w-[25%]">
          <h2 className="text-xl mb-2">In Progress Orders</h2>
          <p className="mb-3">Your In Progress Orders are listed here.</p>
          {orderPageData && orderPageData.all_progress_orders ? (
            orderPageData?.all_progress_orders?.map((order, index) => (
              <OrderBrief
                key={index}
                username={order.consumer_name}
                orderid={order.code}
                createdat={order.created_at}
                lat={order.latitude}
                long={order.longitude}
                status={order.status.name}
                pharmacyid={order.pharmacy_id}
              />
            ))
          ) : (
            <h3 className="text-xl">No Records Found</h3>
          )}
        </section>

        <section className="bg-white text-formisBlack14 p-5 md:px-5 rounded-[10px] h-fit lg:w-[25%]">
          <h2 className="text-xl mb-2">Delivered Orders</h2>
          <p className="mb-3">Your Delivered Orders are listed here.</p>
          {orderPageData?.all_completed_orders ? (
            orderPageData?.all_completed_orders?.map((order, index) => (
              <OrderBrief
                key={index}
                username={order.consumer_name}
                orderid={order.code}
                createdat={order.created_at}
                lat={order.latitude}
                long={order.longitude}
                status={order.status.name}
                pharmacyid={order.pharmacy_id}
              />
            ))
          ) : (
            <h3 className="text-xl">No Records Found</h3>
          )}
        </section>
      </div>
      <Toaster
        position="top-right"
        expand={false}
        closeButton
        richColors
        visibleToasts={2}
      />
    </div>
  );
};

export default Orders;
