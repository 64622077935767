import { useEffect, useState } from "react";
import logo from "../assets/logo-full-black.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { loginApi, otpApi } from "../api";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";

const Login = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");

  const handlePasswordSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await fetch(loginApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        if (data.status === 0) {
          toast.error(data.message);
          setLoading(false);
        } else if (data.status === 1) {
          setStep(2);
          toast.success("Please Enter OTP to Continue")
          setShowPassword(false);
          setLoading(false);
        }
      }
    } catch {}
  };

  const handleOtpSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await fetch(otpApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password: otp,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        if (data.status === 0) {
          toast.error(data.message);
          setLoading(false);
        } else {
          localStorage.setItem("accessToken", data.data.token);
          navigate("/dashboard/order");
          setLoading(false);
        }
      }
    } catch {}
  };

  useEffect(()=>{
    const access = localStorage.getItem("accessToken");
    if(access){
      navigate("/dashboard/order");
    }
  }, [])

  return (
    <main className="flex h-[100svh] justify-center items-center bg-formisBlack15">
      <div className="w-[95vw] sm:w-[60vw] lg:w-[40vw] xl:w-[30vw] bg-formisWhitef0 text-formisBlack14 rounded-[10px] p-10 h-fit m-5">
        <div>
          <img src={logo} alt="FORMIS" className="max-w-[250px] mx-auto mb-10" />
          {/* <p className="text-center opacity-60 max-w-[80%] mx-auto mb-10">
            Enter your email address and password to access admin panel.
          </p> */}
        </div>

        {step === 1 && (
          <form onSubmit={handlePasswordSubmit} className="flex flex-col gap-2">
            <label className="font-medium">Email ID</label>
            <input
              id="email"
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Your Email ID"
              className="p-2 rounded-[10px] border outline-none mb-5"
            />
            <label className="font-medium">Password</label>

            <div className="w-full flex justify-between mb-5">
              <input
                id="password"
                required
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Your Password"
                className="p-2 rounded-l-[10px] outline-none w-[85%] lg:w-[90%]"
              />
              <div
                className="flex items-center justify-center rounded-r-[10px] w-[15%] lg:w-[10%] bg-[#545454]/20 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </div>
            </div>

            <button
              type="submit"
              className="bg-formisBlack15 text-formisWhitef5 rounded-[10px] py-2"
              disabled={loading}
            >
              {loading ? (
                <div className="loader-small mx-auto"></div>
              ) : (
                "Log In"
              )}
            </button>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleOtpSubmit} className="flex flex-col gap-2">
            <label className="font-medium">Email ID</label>
            <input
              disabled
              value={email}
              className="p-2 rounded-[10px] border outline-none mb-5"
            />
            <label className="font-medium">OTP</label>

            <div className="w-full flex justify-between mb-5">
              <input
                type={showPassword ? "text" : "password"}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter Your OTP"
                className="p-2 rounded-l-[10px] border outline-none w-[85%] lg:w-[90%]"
              />
              <div
                className="flex items-center justify-center rounded-r-[10px] w-[15%] lg:w-[10%] bg-[#545454]/20 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </div>
            </div>
            <button
              type="submit"
              className="bg-formisBlack15 text-formisWhitef5 rounded-[10px] py-2"
              disabled={loading}
            >
              {loading ? (
                <div className="loader-small mx-auto"></div>
              ) : (
                "Confirm OTP"
              )}
            </button>
          </form>
        )}
      </div>
      <Toaster
        position="top-right"
        expand={false}
        closeButton
        richColors
        visibleToasts={2}
      />
    </main>
  );
};

export default Login;
