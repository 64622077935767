import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "sidebar",

  initialState: {
    toggle: true,
    mobile: false,
  },

  reducers: {
    toggleSidebar: (state) => {
      state.toggle = !state.toggle;
      state.mobile = !state.mobile;
    },
    closeMobile: (state) => {
      state.mobile = false;
    },
  },
});

export const { toggleSidebar, closeMobile } = sidebarSlice.actions;

export default sidebarSlice.reducer;
