import logo from "../assets/logo-full-white.png";
import { FaUser } from "react-icons/fa";
import { MdFullscreen } from "react-icons/md";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { IoMdMenu } from "react-icons/io";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../features/sidebar/sidebarSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutApi } from "../api";
import { FaHome } from "react-icons/fa";
import { Toaster, toast } from "sonner";

const Topbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(logoutApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        if (data.status === 1) {
          // toast.success("Logged Out Successfully");
          navigate("/");
        }
      }
    } catch {}
  };

  return (
    <div
      className={`h-[80px] py-3 px-5 md:px-10  text-formisWhitef0 bg-formisBlack15
                    flex justify-between items-center ${
                      location.pathname === "/" ? "hidden" : "block"
                    }`}
    >
      <section>
        {/* <IoMdMenu
          className="text-3xl cursor-pointer"
          onClick={() => dispatch(toggleSidebar())}
        /> */}
        {/* <Link to="/dashboard/order/" className="bg-formisPeach p-3 rounded-full text-formisBlack15 text-xl">Home</Link> */}
        <img src={logo} alt="FORMIS" className="max-w-[200px] md:max-w-[250px]" />
      </section>

      <section className="flex items-center justify-end gap-5">
        <div className="flex items-center gap-2">
          <Link to="/dashboard/order" className="bg-[#545454]/30 p-2 rounded-full">
            <FaHome className="text-2xl text-formisPeach" />
          </Link>
        </div>

        <RiLogoutBoxRLine
          onClick={handleLogout}
          className="text-2xl cursor-pointer text-formisPeach"
        />
      </section>
      {/* <Toaster
        position="top-right"
        expand={false}
        closeButton
        richColors
        visibleToasts={2}
      /> */}
    </div>
  );
};

export default Topbar;
